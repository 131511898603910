<template>
  <div class="financialStatistics">
    <el-scrollbar class="elScroll">
      <div class="page-header">
        <div class="hr-top">
          <span>当前平台订单情况</span>
          <img src="@/assets/img/warn-1.png" @click="dialogDetailEvent" alt="">
          <div class="form-H-area">
            <el-select v-model="form.tenantId" filterable placeholder="学校" @change="changeEvent">
              <el-option
                v-for="item in allSchoolData"
                :key="item.tenantId"
                :label="item.tenantName"
                :value="item.tenantId">
              </el-option>
            </el-select>
            <el-select v-model="form.stationId" placeholder="教学点" @change="changeTotal">
              <el-option
                v-for="item in correspondenceStation"
                :key="item.stationsId"
                :label="item.stationsName"
                :value="item.stationsId">
              </el-option>
            </el-select>
            <el-select v-model="form.timeType" filterable @change="changeTotal">
              <el-option
                v-for="(item, index) in yearTimeList"
                :key="index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="hr-buttom">
          <div class="platform-cont">
            <div class="pla-item" @click="watchSch" v-for="(item, index) in orderDetailList" :key="index">
              <div class="pla-item-num">{{ priceShow(item) }}</div>
              <div class="pla-item-cont">
                <img src="@/assets/img/moneyIcon.png" alt="">
                <span>{{ item.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="liveHeader">
        <div class="lr-search">
          <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px" size="mini">
            <el-form-item label="时间维度" class="shortF">
              <el-select v-model="formInline.timeType" filterable placeholder="时间维度" @change="changeTimeType">
                <el-option
                  v-for="(item, key, index) in $common.timeStatisticType"
                  :key="index"
                  :label="item"
                  :value="Number(key)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="统计时间段" label-width="90px">
              <template v-if="formInline.timeType == 1">
                <el-date-picker
                  v-model="dayTimeval"
                  type="daterange"
                  start-placeholder="开始日期"
                  range-separator="至"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                  :picker-options="dayPickerOptions"
                >
                </el-date-picker>
              </template>
              <template v-else>
                <el-date-picker
                  v-model="monTimeVal"
                  type="monthrange"
                  range-separator="至"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  value-format="timestamp"
                  :picker-options="monPickerOptions">
                </el-date-picker>
              </template>
            </el-form-item>
            <el-form-item label="统计省份" class="shortF">
              <el-select v-model="formInline.regionId" filterable placeholder="统计省份" @change="selectRegEvent">
                <el-option
                  v-for="(item, index) in regionData"
                  :key="index"
                  :label="item.name"
                  :value="Number(item.id)">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="统计学校" class="shortF">
              <el-select v-model="formInline.tenantId" filterable placeholder="统计学校" @change="changeSch">
                <el-option
                  v-for="(item, index) in schoolData"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="教学点" class="shortF">
              <el-select v-model="formInline.stationId" placeholder="教学点">
                <el-option
                  v-for="item in correspondenceStationOther"
                  :key="item.stationsId"
                  :label="item.stationsName"
                  :value="item.stationsId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn_area">
            <el-button size="mini" class="mainBtn" @click="onSubmit" plain>搜索</el-button>
            <el-button size="mini" type="info" plain @click="resetEvent">重置</el-button>
          </div>
        </div>
        <div class="echarts-area">
          <v-chart class="chart-school" :option="option" />
        </div>
      </div>
      <div class="page-header">
        <div class="hr-top">
          <span>订单情况</span>
        </div>
        <div class="table-area">
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            height="540"
            align="center"
            row-key='id'>
            <el-table-column
              v-for="(item, index) in columnList"
              :key="index"
              :prop="item.prop"
              :label="item.label ? item.label : '/'"
              :width="item.width"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row[scope.column.property] == null">/</span>
                <span v-else-if="scope.column.property == 'time'">{{scope.row.time == 'total' ? '总计' : scope.row.time }}</span>
                <span v-else>
                  {{ scope.row[scope.column.property] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-scrollbar>

    <dialogDiyNew :isShow="isShowDetail" :dialogDiyData="dialogDetailData"
    @dialogEvent="dialogDetailEvent">
      <div slot="dialogContent" class="fin_area">
        <p><span class="d_title">统计口径：</span>正式商用学校财务数据统计</p>
        <!-- <p>审批单仅对审批通过的订单进行统计，未通过的审批单不参与统计</p> -->
        <p><span class="d_title">平台应收总金额：</span>新增的订单总金额 — 免费的订单金额</p>
        <p><span class="d_title">实收总金额：</span>平台实际收到的金额总数</p>
        <p><span class="d_title">免费总金额：</span>免费的订单金额</p>
        <p><span class="d_title">未结清总金额：</span>已开通学习权限的非免费的订单总金额</p>
        <p><span class="d_title">未支付总金额：</span>未支付的学生订单总金额</p>
        <p>平台应收总金额=实收总金额+未结清总金额+未支付总金额</p>
        <p><span class="d_title">平台总人数：</span>新录入的学生总人数</p>
        <p><span class="d_title">学习权限开通人数：</span>已开通学习权限正在学习的学生总人数</p>
        <p><span class="d_title">总缴费人数：</span>订单状态为已支付的付费类型的学生人数</p>
        <p><span class="d_title">免费人数：</span>订单的付费类型为免费的学生总人数</p>
        <p><span class="d_title">未支付人数：</span>未支付的学生总人数</p>
        <p>平台总人数=总缴费人数+免费人数+未支付人数+未结清人数</p>
        <p>学习权限开通人数=总缴费人数+免费人数+未结清人数</p>
      </div>
    </dialogDiyNew>

    <!-- 学校订单统计详情 -->
    <dialogDiyNew :isShow="isShowSchDetail" :dialogDiyData="dialogSchDetailData"
    @dialogEvent="dialogSchDetailEvent">
      <div slot="dialogContent" class="sch_detail_area">
        <schoolOrderStatisticsDetails ref="schda"></schoolOrderStatisticsDetails>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
// import tableDetail from './tableDetails'
const dialogDiyNew = () => import('@/components/commonModule/dialogDiyNew')
const schoolOrderStatisticsDetails = () => import('./schoolOrderStatisticsDetails')
export default {
  name: 'financialStatistics',
  components: {
    // tableDetail,
    dialogDiyNew,
    schoolOrderStatisticsDetails
  },
  data() {
    let that = this;
    async function lazyLoad(node, resolve) {
      const { level, value } = node;
      if (level == '0') return;
      let nodes = await that.getCities(value)
      nodes.forEach(item => item.leaf = level >= 1)
      setTimeout(() => {
        resolve(nodes);
      }, 800);
    }
    return {
      orderDetailList: [
        {
          label: '平台应收总金额',
          price: null,
          name: 'receivableMoney',
          type: 1
        },
        {
          label: '实收总金额',
          price: null,
          name: 'receiptMoney',
          type: 1
        },
        // {
        //   label: '免费总金额',
        //   price: null,
        //   name: 'freeMoney',
        //   type: 1
        // },
        {
          label: '未结清总金额',
          price: null,
          name: 'unclearedMoney',
          type: 1
        },
        {
          label: '未支付金额',
          price: null,
          name: 'unpaidMoney',
          type: 1
        },
        {
          label: '平台总人数',
          num: null,
          name: 'studentNum',
          type: 2
        },
        {
          label: '学习权限开通人数',
          num: null,
          name: 'learningOpenNum',
          type: 2
        },
        {
          label: '总缴费人数',
          num: null,
          name: 'payNum',
          type: 2
        },
        {
          label: '免费人数',
          num: null,
          name: 'freeNum',
          type: 2
        },
        {
          label: '未支付人数',
          num: null,
          name: 'unpaidNum',
          type: 2
        },
      ],
      coursewareResources: {},
      formInline: {
        beginTime: null,
        endTime: null,
        regionId: null,
        tenantId: null,
        stationId: null,
        timeType: 2
      },
      dayTimeval: [],
      dayPickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
            let start = new Date().setHours(0, 0, 0, 0);
            start = start - 3600 * 1000 * 24 * 7;
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近两周',
          onClick(picker) {
            let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
            let start = new Date().setHours(0, 0, 0, 0);
            start = start - 3600 * 1000 * 24 * 14;
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            let end = new Date().setHours(0, 0, 0, 0) + (24 * 60 * 60 * 1000 - 1);
            let start = new Date().setHours(0, 0, 0, 0);
            start = start - 3600 * 1000 * 24 * 30;
            picker.$emit('pick', [start, end]);
          }
        }],
        // disabledDate(v) {
        // // 4、日期限制只能选当天和前30天
        //   let limitDate =
        //   v.getTime() < new Date().getTime() - 31 * 86400000 ||
        //   v.getTime() >= Date.now() + 8.64e7;
        //   // 86400000:一天的毫秒
        //   return limitDate;
        // }
      },
      monTimeVal: [],
      monPickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()]);
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date();
            const start = new Date(new Date().getFullYear(), 0);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 6);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近十二个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 12);
            picker.$emit('pick', [start, end]);
          }
        }],
        // disabledDate(v) {
        //   let limitDate =
        //   v.getTime() < new Date().getTime() - 12 * 31 * 86400000 ||
        //   v.getTime() >= Date.now() + 8.64e7;
        //   return limitDate;
        // }
      },
      regionData: [],
      props: {
        lazy: true,
        value: 'code',
        label: 'name',
        lazyLoad
      },
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          bottom: '10%',
          left: 'center'
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '4%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: [],
            axisLabel: {
              interval: 2
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '应收金额',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          },
          {
            name: '实收金额',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            data: []
          }
        ]
      },
      tableData: [],
      columnList: [
        {
          prop: "time",
          label: "时间"
        },
        {
          prop: "orderMoney",
          label: "订单金额"
        },
        {
          prop: "receiptMoney",
          label: "实收金额"
        },
        {
          prop: "payNum",
          label: "缴费人数"
        },
        {
          prop: "freeNum",
          label: "免费人数"
        },
        {
          prop: "unpaidNum",
          label: "未缴费人数"
        }
      ],
      isShowDetail: false,
      dialogDetailData: {
        title: "当前平台订单情况",
        sizeStyle: { width: "560px", height: "540px" }
      },
      schoolData: [],
      form: {
        tenantId: null,
        stationId: null,
        timeType: 1, // 1.当前年度 2.全部年度统计
      },
      yearTimeList: [
        {
          label: '当前年度数据统计',
          value: 1
        },
        {
          label: '所有年度数据统计',
          value: 2
        }
      ],
      allSchoolData: [],
      correspondenceStation: [],
      correspondenceStationOther: [],
      isShowSchDetail: false,
      dialogSchDetailData: {
        title: "学校订单统计详情",
        sizeStyle: { width: "1500px", height: "750px" }
      },
    }
  },
  mounted() {
    this.getTenantDown()
    this.getMon()
    this.financialStatisticTotal()
    this.getProviceEvent()
  },
  methods: {
    priceShow(item) {
      let val = item.type == 1 && item.price ? Math.round(item.price) : item.type == 2 && item.num ? item.num : 0
      return val;
    },
    // 获取学校数据
    getTenantDown() {
      this.$request.getTenantDownData({}, res => {
        if(res.code == 0) {
          this.allSchoolData = res.data;
        }
      })
    },
    // 获取教学点数据
    getTheDropDownBoxData(name) {
      let obj = {
        tenantId: this[name].tenantId
      }
      this.$request.getTheDropDownBoxs(obj, res => {
        if (res.code == 0) {
          if(name == 'form')
            this.correspondenceStation = res.data
          else
            this.correspondenceStationOther = res.data
        }
      })
    },
    changeSch() {
      this.formInline.stationId = null
      this.correspondenceStationOther = []
      this.getTheDropDownBoxData('formInline')
    },
    changeEvent() {
      this.form.stationId = null
      this.correspondenceStation = []
      this.getTheDropDownBoxData('form')
      this.changeTotal()
    },
    changeTotal() {
      this.financialStatisticTotal()
    },
    financialStatisticTotal() {
      this.$request.financialStatisticTotal(this.form).then(res => {
        if (res.data.code == 0) {
          this.coursewareResources = res.data.data;
          this.orderDetailList.forEach(item => {
            if (item.type == 1)
              item.price = res.data.data[item.name]
            else
              item.num = res.data.data[item.name]
          })
        }
      })
    },
    // 获取12个月内的时间段
    getMon() {
      let end = new Date()
      let start = new Date()
      start = start.setMonth(start.getMonth() - 12)
      start = this.$way.getStartTime(start)
      end = this.$way.getEndTime(end)
      this.monTimeVal = [start, end]
      this.financialStatisticDetail()
    },
    financialStatisticDetail() {
      let obj = JSON.parse(JSON.stringify(this.formInline))
      obj.beginTime = this.formInline.timeType == 1 ? this.dayTimeval[0] : this.monTimeVal[0]
      obj.endTime = this.formInline.timeType == 1 ? this.$way.dateReturnTimestamp(this.dayTimeval[1], 'end') : this.$way.getEndTime(this.monTimeVal[1])
      this.$request.financialStatisticDetail(obj).then(res => {
        if (res.data.code == 0) {
          let data = JSON.parse(JSON.stringify(res.data.data))
          let list = data.list
          list.unshift(data.total)
          list[0].time = 'total'
          this.tableData = list
          this.getEchartsData(res.data.data.list)
        }
      })
    },
    getEchartsData(data) {
      this.option.xAxis[0].data = []
      this.option.series[0].data = []
      this.option.series[1].data = []
      data.forEach((item, index) => {
        this.option.xAxis[0].data.push(item.time)
        this.option.series[0].data.push(item.orderMoney)
        this.option.series[1].data.push(item.receiptMoney)
      })
      this.option.dataZoom = [
        {
          show: this.option.xAxis[0].data.length > 5 ? true : false,
          // show: true,
          height: 12,
          xAxisIndex: [0],
          bottom: "4%",
          // start: 0,
          // end: 100,
          maxValueSpan: 12,
          handleIcon:
            "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
          handleSize: "110%",
          handleStyle: {
            color: "#d3dee5",
          },
          // textStyle: {
          //   color: "#666",
          // },
          textStyle: false,
          borderColor: "#90979c",
        }
      ]
    },
    changeTimeType() {
      this.dayTimeval = []
      this.monTimeVal = []
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getPermissionProvinces({}, res => {
        if(res.code == 0) {
          this.regionData = res.data;
        }
      })
    },
    // 获取市
    getCities(parentCode) {
      return new Promise((resolve, reject) => {
        this.$request.getCity({parentCode}, res =>{
          if(res.code == 0) {
            resolve(res.data)
          }
        })
      })
    },
    // 获取学校
    getListData() {
      let obj = {
        page: 1,
        pageSize: 100,
        region: this.formInline.regionId
      }
      this.$request.getListData(obj, res => {
        if (res.code == 0) {
          this.schoolData = res.data.page.records
        }
      })
    },
    onSubmit() {
      if ((this.formInline.timeType == 1 && this.dayTimeval.length == 0) || (this.formInline.timeType == 2 && this.monTimeVal.length == 0)) {
        this.Warn('请选择统计时间段')
        return;
      }
      this.financialStatisticDetail()
    },
    // 重置
    resetEvent() {
      Object.assign(this.$data.formInline, this.$options.data().formInline)
      this.getMon()
    },
    // 排序
    sortEvent(column) {
      this.formInline.sort = column.order == "descending" ? -1 : column.order == "ascending" ? 1 : null
      this.getPlatformTableData()
    },
    // 查看详情
    watchDetails(row) {
      let timearea;
      let bTime; // 筛选区时间段初始时间
      let eTime; // 筛选区时间段结束时间
      let { timeType } = this.formInline
      if (row.time == 'total') {
        let start = timeType == 1 ? this.$way.timestampReturnDate(this.dayTimeval[0]) : this.$way.timestampReturnDate(this.monTimeVal[0], 'dateYM')
        let end = timeType == 1 ? this.$way.timestampReturnDate(this.dayTimeval[1]) : this.$way.timestampReturnDate(this.monTimeVal[1], 'dateYM')
        timearea = `${start}至${end}`
        bTime = timeType == 1 ? this.dayTimeval[0] : this.monTimeVal[0]
        eTime = timeType == 1 ? this.dayTimeval[1] : this.monTimeVal[1]
      } else {
        timearea = row.time
      }
      let obj = {
        ...row,
        timearea,
        timeType,
        bTime,
        eTime
      }
      this.dialogDetailEvent()
      this.$nextTick(() => {
        this.$refs.da.showEvent(obj)
      })
    },
    dialogDetailEvent() {
      this.isShowDetail = !this.isShowDetail
    },
    selectRegEvent() {
      this.formInline.tenantId = null
      this.getListData()
    },
    dialogSchDetailEvent() {
      this.isShowSchDetail = !this.isShowSchDetail
    },
    watchSch() {
      this.dialogSchDetailEvent()
      let obj = this.$lodash.cloneDeep(this.form)
      if(obj.tenantId) obj.tenantName = this.allSchoolData.filter(i => i.tenantId == obj.tenantId)[0].tenantName
      this.$nextTick(() => {
        this.$refs.schda.showEvent(obj)
      })
    }
  },
}
</script>

<style lang="less" scoped>
  .financialStatistics {
    width: 100%;
    height: 100%;
    /deep/ .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .page-header {
      background-color: #fff;
      margin-bottom: 20px;
      .hr-top {
        padding: 30px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EFEFEF;
        position: relative;
        & > span:nth-of-type(1) {
          font-size: 26px;
          color: #16222E;
        }
        img {
          width: 24px;
          height: 24px;
          margin-left: 10px;
          cursor: pointer;
        }
        .form-H-area {
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translateY(-50%);
          /deep/ .el-select:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
      .platform-cont {
        display: flex;
        justify-content: space-between;
        .pla-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 33px 0;
          border-right: 1px solid #EFEFEF;
          &:nth-last-of-type(1) {
            border: 0 none;
          }
          .pla-item-num {
            font-size: 28px;
            font-weight: 400;
            color: #363840;
            cursor: pointer;
          }
          .pla-item-cont {
            display: flex;
            align-items: center;
          }
          img {
            width: 14px;
            height: 14px;
            margin-right: 6px;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
    .liveHeader {
      background-color: #fff;
      box-sizing: border-box;
      margin-bottom: 20px;
      .lr-header {
        padding: 30px;
        border-bottom: 1px solid #EFEFEF;
        display: flex;
        align-items: center;
        & > span:nth-of-type(1) {
          font-size: 26px;
          color: #16222E;
          margin-right: 24px;
        }
      }
      .lr-search {
        box-sizing: border-box;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #EFEFEF;
        .el-form {
          /deep/ .el-form-item {
            margin-bottom: 0;
          }
          .shortF {
            /deep/ .el-input__inner {
              width: 140px;
            }
          }
        }
      }
    }
    .echarts-area {
      padding: 30px;
      .chart-school {
        height: 360px;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
      }
    }
    .table-area {
      box-sizing: border-box;
      padding: 20px;
    }
    .fin_area {
      padding: 20px;
      line-height: 30px;
      font-size: 16px;
      color: #333;
      // p:first-of-type {
      //   font-size: 20px;
      // }
      .d_title {
        font-weight: 600;
      }
    }
  }
</style>